import './App.css';
import { BrowserRouter, Link, Route, Routes, useLocation} from 'react-router-dom';
import cert from "./images/certificate.png"
import iccw from "./images/iccw.png"
import hkimza from "./images/hkimza.png"
import world from "./images/world.png"
import tick from "./images/tick.png"
import experience from "./images/experience.png"
import mission from "./images/mission.png"
import mission2 from "./images/mission2.jpg"
import mission3 from "./images/mission3.jpg"
import pcd from "./images/pcd.png"
import aimoosi from "./images/aimoosi.png"
import certicon from "./images/certicon.png"
import masterclassicon from "./images/masterclass.png"
import worldicon from "./images/worldicon.png"
import hanife from "./images/hanife2.png"

import { useEffect, useState } from 'react';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>
        <MobileHeader/>
      </div>
      <Routes>
       <Route path='/' element={<Hero/>}/>
       <Route path="/hakkimizda" element={<Hakkimizda/>}/>
       <Route path="/vizyonumuz" element={<Vizyonumuz/>}/>
       <Route path="/misyonumuz" element={<Misyonumuz/>}/>
       <Route path="/sinavlarimiz" element={<Sinavlarimiz/>}/>
       <Route path="/markalarimiz" element={<Markalarimiz/>}/>
       <Route path="/workshoplarimiz" element={<Workshoplarimiz/>}/>
       <Route path="/distributorluklerimiz" element={<Distributorluklerimiz/>}/>
       <Route path="/iletisim" element={<Contact/>}/>
      </Routes>
    </BrowserRouter>
    
  );
}



function Header(){
  let i = 0
  const location = useLocation()

  useEffect(() => {
    if(location.pathname === "/") i = 0
    else if(location.pathname === "/hakkimizda") i = 1
    else if(location.pathname === "/vizyonumuz") i = 2
    else if(location.pathname === "/misyonumuz") i = 3
    else if(location.pathname === "/sinavlarimiz") i = 4
    else if(location.pathname === "/markalarimiz") i = 5
    else if(location.pathname === "/workshoplarimiz") i = 6
    else if(location.pathname === "/iletisim") i = 7
    else i = 8
    let pDiv = document.getElementById("Menu")
    let cDiv = pDiv.children
    for(let n=0;n<pDiv.children.length;n++){
      cDiv[n].style.background = "none"
      cDiv[n].style.color = "white"
    }
    cDiv[i].style.background = "white"
    cDiv[i].style.color = "black"
  },[location])

  return(
    <div id='Header'>
      <div id='Menu'>
        <Link to={"/"}>Anasayfa</Link>
        <Link to={"/hakkimizda"}>Hakkımızda</Link>
        <Link to={"/vizyonumuz"}>Vizyonumuz</Link>
        <Link to={"/misyonumuz"}>Misyonumuz</Link>        
        <Link to={"/sinavlarimiz"}>Sınavlarımız</Link>        
        <Link to={"/markalarimiz"}>Markalarımız</Link>
        <Link to={"/workshoplarimiz"}>Workshoplarımız</Link>
        <Link to={"/iletisim"}>İletişim</Link>
      </div>
      <div id='SiteID'>
        <h1>Hanife Kolay</h1>
        <span>2023 - Tüm hakları saklıdır</span>
      </div>
      
    </div>
  )
  
}

function MobileHeader(){
  let [isMenuOpen, setIsMenuOpen] = useState(false)

  async function handleMenu(){
    const menuElement = document.getElementById('MobileHeader').children.namedItem('Menu')
    if(isMenuOpen){
      menuElement.style.setProperty('display','none')
      isMenuOpen = false
    }else{
      menuElement.style.setProperty('display','grid')
      isMenuOpen = true
    }
  }

  return(
    <div id='MobileHeader'>
      <h1>Hanife Kolay</h1>
      <button onClick={handleMenu}>Menu</button>
      <div id='Menu'>
        <Link onClick={handleMenu} to={"/"}>Anasayfa</Link>
        <Link onClick={handleMenu} to={"/hakkimizda"}>Hakkımızda</Link>
        <Link onClick={handleMenu} to={"/vizyonumuz"}>Vizyonumuz</Link>
        <Link onClick={handleMenu} to={"/misyonumuz"}>Misyonumuz</Link>        
        <Link onClick={handleMenu} to={"/sinavlarimiz"}>Sınavlarımız</Link>        
        <Link onClick={handleMenu} to={"/markalarimiz"}>Markalarımız</Link>
        <Link onClick={handleMenu} to={"/workshoplarimiz"}>Workshoplarımız</Link>
        <Link onClick={handleMenu} to={"/iletisim"}>İletişim</Link>
      </div>
    </div>
  )
}

function Hero(){
  let [visitor, setVisitor] = useState(8694)
  let [cert, setCert] = useState(0)
  let [masterclass, setMasterclass] = useState(0)

  useEffect(() => {
    visitorCounter()
    certCounter()
    masterclassCounter()
  }, [])

  async function masterclassCounter(){
    let mcInterval = setInterval(() => {
      if(masterclass < 377){
        masterclass += 5
        setMasterclass(masterclass)
      }else{
        setMasterclass(377)
        clearInterval(mcInterval)
      } 
    }, 10)
  }

  async function certCounter(){
    let certInterval = setInterval(() => {
      if(cert < 999){
        cert += 5
        setCert(cert)
      }else{
        setCert(1000)
        clearInterval(certInterval)
      } 
    }, 10)
  }

  async function visitorCounter(){
    let counter = 8694
    let counterTimeout = setInterval(() => {
      let randomNumber = Number((Math.random()*10).toFixed(0))
      counter += randomNumber
      setVisitor(counter)
      if(cert < 999){
        cert++
        setCert(cert)
      }
    }, 1000)
  }

  return(
    <div id='Hero'>
      <div id='HeroTop'>
      <div id='HeroSpacer'></div>
      <div id='HeroCell'>
        <img id='HKimza' src={hkimza} alt='hkimza'/>
        <h2>KENDİNİN EN İYİ HALİ OL</h2>
        <span>Hanife Kolay International Beauty Academy, formatı ile verdiği Uluslararası Workshop eğitim programları ile güzellik sektöründe tartışmasız lider konumunda marka olmuştur.</span>
        <span>Her zaman ilklerin öncülüğünü yapmış olan Hanife Kolay, yenilikleri yakından takip ederek eğitimlerine her geçen gün yenisini eklemiş ve kalitesine kalite ekleyerek verdiği eğitimlerle sektöre bir çok uzman kazandırmıştır.</span>
        <div id='HeroButtons'>
          <button onClick={() => window.open("https://wa.me/+905309301722")}>Whatsapp</button>
          <button onClick={() => window.open("https://www.instagram.com/hanifekolayacademy/")}>Instagram</button>
        </div>
        <span style={{color: 'rgb(226,229,180)'}}>1998'DEN BU YANA GÜZELLİK</span>
      </div>
      </div>
      <div id='HeroBottom'>
        <div id='HeroBottomLeft'>
          <span>Worldclass Beauty & Masterclass</span>
          <div id='GoldRibbon'></div>
        </div>
        <div id='HeroBottomRight'>
          
          <div id='HeroBottomRightCell'>
            <img src={certicon} alt='certicon'/>
            <div>
              <h3>{cert}+</h3>
              <span>Sertifikalandırma</span>
            </div>
          </div>
          <div id='HeroBottomRightCell'>
          <img src={masterclassicon} alt='masterclass'/>
          <div>
            <h3>{masterclass}</h3>
            <span>Masterclass</span>
          </div>
          </div>
          <div id='HeroBottomRightCell'>
          <img src={worldicon} alt='worldicon'/>
          <div>
            <h3>17+</h3>
            <span>Ülke</span>
          </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

function Hakkimizda(){
  return(
    <div id='Hakkimizda'>
      <h1>Hakkımızda</h1>
      <h2>HANİFE KOLAY INTERNATIONAL BEAUTY ACADEMY'ye hoşgeldiniz!</h2>
      <span id='HakkimizdaText'>
          Hanife Kolay International Beauty Academy'nin kurucusu Hanife Kolay'dır.
        <br/>
        Kurumunda Uluslararası Onaylı  Mesleki Belgelendirme & Sahibi Oldugu Markaların Eğitimi & Güzellik Sektöründe Organizasyon & Danışmanlık Hizmetleri ile de iştigal etmektedir.
        <br/>
          Hanife Kolay International Beauty Academy; MYK Aday Kayıt ve Sınav Merkezidir. Uluslararası mesleki belgelendirme hizmeti ve danışmanlığı vermektedir.
      </span>
      <div id='CellContainer'>
        <div id='HakkimizdaCell'>
          <img src={world} alt="world"/>
          <h3>Uluslararası</h3>
          <span>Hanife Kolay, MYK Mesleki Yeterlilik Kurumu Sınav Değerlendirici ve Karar Vericisi olarak da Toplumsal ve Kişisel Hizmetler alanında ulusal ve uluslararası geçerliliği olan işyeri açma ve çalıştırma için gerekli olan belgeler için 14 ayrı meslek dalında sınav yapıcı ve değerlendirici olarak görev yapmaktadır.</span>
        </div>
        <div id='HakkimizdaCell'>
          <img src={tick} alt="tick"/>
          <h3>Kaliteli</h3>
          <span>Kuruluşundan bu yana Hanife Kolay International Beauty Academy;  Uluslararası güvenli, yenilikçi, yüksek kaliteli mikropigmentasyon & Microblading & Dermapigmentasyon ürünleri, kalıcı makyaj cihazları, ekipmanları, aksesuarları Uygulama & Eğitim ve Ürün tedariği ile dünya çapında haklı bir yer edinmiştir.</span>
        </div>
        <div id='HakkimizdaCell'>
          <img src={experience} alt="exp"/>
          <h3>Tecrübeli</h3>
          <span>Hanife Kolay, Güzellik Sektöründe 28 yılı aşkın deneyime sahiptir.  15 yıldır kalıcı makyaj ve medikal kalıcı makyaj meslek alanında profesyonel olarak çalışmaktadır. Mesleğin profesyoneli olmak isteyenlere bu alanlarda uluslararası workshop ve programlar  düzenleyerek bilgilerini paylaşmaktadır.</span>
        </div>
      </div>
      <span>
      AIMOOSI® PERMANENT MAKE UP (Kozmetik Kalıcı Makyaj & Microblading) Uluslararası grand master eğitmeni ve Türkiye, Azerbeycan, Yunanistan ülkelerinin Exclusive Resmi  Distribürörüdür.
        <br/>
          SOBER SKIN® DERMAPIGMENTATION
          (Paramedikal Mikropigmentasyon) markasının Uluslararası Grand Master Eğitmeni ve Türkiye, Azerbeycan, Yunanistan ülkelerinin Exclusive Resmi  Distribürörüdür.
        <br/>
          Resmi ülke distribütörü olduğu markalarıyla birlikte her sene  ulusal ve uluslararası güzellik  fuarı ve festivallere kurum olarak katılmaktadır.
      </span>
    </div>
  )
}

function Vizyonumuz(){
  return(
    <div id='Vizyonumuz'>
      <h1>Vizyonumuz</h1>
      <span>
      HANİFE KOLAY AKADEMİ, trendleri, yenilikleri ve teknolojik gelişmeleri özümseyip yaşamla bütünleştirebilen, çağdaş düşünce yapısına sahip, alanında uzman eğitim kadrosuyla profesyoneller yetiştirme sorumluluğu ile, akademik düzeyde meslek edindirme ve sertifikasyon programlarını hayata geçirmeyi vizyon edinmiştir.

      </span>
      <div id='VizyonumuzCell'>
        <img src={iccw} alt="vizyon"/>
        <span>
        ICCW Akreditasyon sistemiyle tüm dünyada geçerliliği olan elektronik sertifikasyon uygulamasına sahip olan Hanife Kolay Akademi, öğrencilerinin mezun olduktan sonra tüm dünyada kariyer fırsatlarına erişebilmesine de olanak sağlamaktadır. 
      Ayrıca öğrencileri geliştirmek amacıyla; tecrübeli ve uzman eğitmenlerin eşliğinde dünya markalarıyla sağlanan verdiği eğitimlerin yanısıra bir çok sektörel uygulamaları zaman zaman eğitim semineri olarak kursiyerlerine sunmakta ve böylelikle kursiyerlerin ufku geniş ve teknolojiyle barışık şekilde iş hayatına katılmasını sağlamaktadır.
        </span>
      </div>
      <div id='VizyonumuzCell'>
        <span>
        Mezun öğrencilerine uzun vadeli planlarla istihdam sağlayarak, sektörün kalifiye eleman ihtiyaçlarını karşılayacak düzeyde beklentilere cevap vermektedir.
        Her yıl sonunda bir yıl içnde mezun olmuş tüm öğrencilerine bir sertifika töreni hazırlayıp, özel bir kep giyme töreni ile onları ödüllenmektedir. Ayrıca çeşitli aktiviteler ve tanışma toplantıları düzenleyerek kursiyerlerini sosyal anlamda geliştirmeye çalışmaktadır.
        </span>
        <img src={cert} alt="vizyon"/>
      </div>
    </div>
  )
}

function Misyonumuz(){
  return(
    <div id='Misyonumuz'>
      <h1>Misyonumuz</h1>
      <span>
            HANİFE KOLAY INTERNATIONAL BEAUTY ACADEMY olarak amacımız; sektördeki eğitimli profesyonellerin sayısını arttırmak, markalarımızın başarısını ve itibarını günden güne yükselterek devamlılığını sağlamaktır. Dünya çapındaki başarımızı ulusal  ve uluslararası platformda eğitimli ve profesyonel kadromuzla gururla temsil etmektir.
            Güzellik sektöründe uzman olabilmek için, doğru bir eğitim eşliğinde  başlangıç yapmak birinci gerekliliktir.
            <br/><br/>
            Bununla birlikte kişinin; yenilenen teknoloji , cihaz ve teknikleri takip ederek; dünya standartlarında kendi bilgilerini ve teknikleri güncelleyerek geliștirmesi gerekir.
            Hanife Kolay International Beauty Academy olarak son teknoloji cihaz, pigmentler, serumlar ve dünyaca kabul görmüș  profesyonellerin tercihi olan markalarımızın  diğer yardımcı  ürünleriyle, Uluslararası Baș Eğitmen Worlclass Grand Master & Trainer Hanife Kolay eğitmenliğinde sizlere kaliteyi sunuyoruz.
            <br/><br/>
            Hanife Kolay International Beauty Academy olarak;  Ulusal ve uluslararası akredite belgeli eğitimlerimizle ve ilaveten MYK Uluslararası Mesleki Yeterlilik sistemiyle tüm dünyada kariyer fırsatlarına erişebilmenize olanak sağlamaktayız.
            Uluslararası Mesleki Yeterlilik sistemiyle tüm dünyada geçerliliği olan elektronik sertifikasyon uygulamasına sahip olan HANİFE KOLAY INTERNATIONAL BEAUTY ACADEMY, katılımcılarının tüm dünyada kariyer fırsatlarına erişebilmesine de olanak sağlamaktadır.
        </span>
      <div id='MisyonumuzCell'>
        <img src={mission} alt="misyonResim"/>
        <img src={mission2} alt="misyonResim"/>
        <img src={mission3} alt="misyonResim"/>
      </div>
      
    </div>
  )
}

function Sinavlarimiz(){
  return(
    <div id='Sinavlarimiz'>
      <h1>Sınavlarımız</h1>
      <h2>MYK MESLEKİ YETERLİLİK KURUMU SINAVLARIMIZ</h2>
      <span>
      Hanife Kolay International Beauty Academy olarak, MYK-Mesleki Yeterlilik Kurumu Aday Kayıt ve Sınav Merkezi olarak hizmet vermekteyiz. Uluslararası mesleki belgelendirme hizmetleri ve danışmanlığı konusunda uzmanlaşmış bir kuruluşuz. Mesleklerde yetkilendirilmiş kuruluş olan PAKSOY AKADEMİ tarafından tanımlanmış yeterlilik standartları ve ulusal kalite güvence sistemi çerçevesinde mesleki yeterliliklerinizi tescil ettirme imkanı sunmaktayız.
      </span>
      <h2>Neden MYK Mesleki Yeterlilik Kurumu Sınavları?</h2>
      <span>Eğer herhangi bir kurs bitirme belgeniz yoksa, çalışma süresi sigortanız mevcut değilse veya mesleki eğitim merkezine gitmek için vaktiniz yoksa endişelenmeyin! Aynı zamanda, ustalık belgeniz olmadığı için işyeri açamıyorsanız, Europass Sertifika Eki ile uluslararası geçerliliği olan Ustalık veya Kalfalık Belgesi almak için sınava girip MYK Mesleki Yeterlilik Belgenizi alabilirsiniz.</span>
      <h2>Hedeflerinize Ulaşmak İçin Hizmetlerimiz</h2>
      <span>Kalıcı makyaj alanında uzmanlaşmış kişiler olarak, toplumsal ve kişisel hizmetlerdeki mesleki yeterlilik belgelendirme sınavlarımızla kariyerinizde bir adım öne çıkmanızı sağlamak istiyoruz. MYK tarafından tanınan yeterlilik standartlarına uygun olarak gerçekleştirdiğimiz sınavlarla, ulusal ve uluslararası alanda mesleki yeterliliğinizi kanıtlamanızı hedefliyoruz.</span>
      <h2>Sınav Merkezimizde Neler Bulabilirsiniz?</h2>
      <span>Sınav merkezimizde, uzman eğitmenler tarafından desteklenen eğitim materyalleri ve kaynaklar sunulmaktadır. Sınav sürecine uygun hazırlık yapabilmeniz için sizlere rehberlik ediyoruz. Mesleki yeterlilik belgenizi almanız için gerekli adımları birlikte atıyoruz ve başarınızı destekliyoruz.</span>
      <h2>Uluslararası Geçerliliği Olan Mesleki Belge İmkanı</h2>
      <span>MYK Mesleki Yeterlilik Belgesi, sadece Türkiye'de değil, uluslararası alanda da tanınan ve geçerliliği olan bir belgedir. Bu belgeyle kariyerinizde daha geniş bir alana açılabilir ve uluslararası alanda çalışma imkanı elde edebilirsiniz.</span>
      <h2>Sınavlara Katılım ve Kayıt İşlemleri</h2>
      <span>Sınavlarımıza katılmak ve MYK Mesleki Yeterlilik Belgenizi almak için lütfen bizimle iletişime geçin. Uzman ekibimiz, sınav kayıt süreci ve sınav hazırlıkları konusunda size yardımcı olacaktır.</span>
      <span>Meslek yaşamınızda daha üst seviyelere çıkmak ve uzmanlığınızı resmileştirmek için MYK Mesleki Yeterlilik Belgesi'ni almak için doğru adrestesiniz. Hanife Kolay International Beauty Academy olarak, size bu önemli adımda destek vermek için buradayız!</span>
    </div>
  )
}

function Markalarimiz(){
  return(
    <div id='Markalarimiz'>
      <h1>Markalarımız</h1>
      <div id='MarkalarimizContainer'>
      <div id='MarkalarimizCell'>
        <img src={aimoosi} alt='aimoosiLOGO'/>
        <span>
        AIMOOSI®, uluslararası alanda güvenli ve yenilikçi bir marka olarak öne çıkmaktadır. Mikropigmentasyon ürünleri, microblading ürünleri ve cihazları alanında yüksek kaliteli ürünler sunan AIMOOSI®, Almanya menşeili ve patentli ürünleri ile dikkat çekmektedir. Kalıcı Makyaj, Microblading ve Medical Kalıcı Makyaj için geniş bir ürün yelpazesi sunan AIMOOSI®, hem etkili sonuçlar hem de güvenli uygulamalar sunmayı taahhüt eder.
        </span>
      </div>
      <div id='MarkalarimizCell'>
        <img src={pcd} alt='pcdLOGO'/>
        <span>
        PCD®, kalıcı makyaj alanında global prestije sahip bir marka olarak bilinir. Mikropigmentasyon cihazları ve ekipmanları, patentli teknolojiler ve Almanya menşeili kaliteli ürünlerle üretilir. Mikropigmentasyon uygulamalarında mükemmel sonuçlar elde etmenizi sağlayan PCD®, aynı zamanda güvenli ve konforlu uygulamalar sunma konusunda da öncüdür. Türkiye'de PCD olarak, üstün hizmet ve kalitede taahhütümüzü standartların üzerine çıkararak sürdürmekteyiz.
        </span>
      </div>
      </div>
      
    </div>
  )
}

function Workshoplarimiz(){
  return(
    <div id='Workshoplarimiz'>
      <h1>Workshoplarımız</h1>
      <h2>UYGULAMALI VE SERTİFİKALI WORKSHOP PROGRAMLARIMIZ</h2>
      <span>Tecrübeli ve uzman eğitmenlerin kadrosuyla uluslararası geçerliliğe sahip workshop programlarını dünyadaki gelişmelere göre yenileyen Hanife Kolay International Beauty Academy, birçok uzmanlık gerektiren konuda bireylere ilgi duydukları konuları, kendi kariyerleri haline getirme olanağı sunmaktadır.</span>
      <span>Güzellik sektöründe ihtiyaçlara göre özelleştirilmiş workshop programlarımız ve marka bilinirliğimiz ile siz müşterilerimize güzellik sektöründe en iyi hizmeti sağlamak amacıyla en tecrübeli ve başarılı eğitimcilerimizle çalışmanız için oluşturulmuştur.</span>
      <span>Güzellik sektöründe uzaktan (online) ve yüz yüze gerçekleştirdiğimiz sektörel workshop konularımız, mesleki ve kişisel gelişiminizde farkındalık ve farklılık yaratmak en önemli hedeflerimizden biridir.</span>
      <h2>UZAKTAN WORKSHOP PROGRAMLARIMIZ</h2>
      <span>Uzaktan workshop programlarınızı tamamladığınızda, tüm bilgilerinizi pekiştirmiş olarak, aklınızda soru işareti kalmadan almış olduğunuz workshop programınızı tamamlamış olursunuz. Workshoplarda fırsat eşitliğini uluslararası platformlara taşıyan bu sistemde, workshopların kalitesi, başarı oranı ve güvenilirliği ön plandadır.</span>
      <span>Teknolojik gelişmeler ve küreselleşme sayesinde ülkeler arası sınırların sanal boyutta kalktığı bu dönemde, birçok açıdan desteklenen uluslararası sertifika programları; yalnızca bireysel değil, kurumsal boyutta da tercih edilmektedir. Sektörde iş hayatına yeni atılacak mezunların, kariyer planlamasını başka boyutlara taşımak isteyen deneyimli bireylerin veya çalışanlarının gelişmesine katkı sağlamak isteyen kurumlar, kuruluşlar ve özel firmaların tercih ettiği bu workshop programları sayesinde katılımcılar kendilerini ve varsa temsil ettikleri kurum veya şirketleri ülke sınırları dışında bir adım ileriye taşımaktadır.</span>
    </div>
  )
}

function Distributorluklerimiz(){
  return(
    <div id='Distributor'>
      <h1>Distribütörlüklerimiz</h1>
    </div>
  )
}

function Contact(){
  return(
  <div id='Contact'>
    <h1>İletişim</h1>
    <h2>Hanife Kolay International Beauty Academy</h2>
    <div style={{justifySelf:"center", width:"100%"}}>
    <iframe title='Harita' width="50%" height="300" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Yeni%C5%9Fehir%20Mah.%20Osmanl%C4%B1%20Bul.%20Aeropark%20Sitesi%20A%20Blok%20No:%2011A%20%C4%B0%C3%A7%20Kap%C4%B1%20No:%2028%20Posta%20Kodu:34912%20%20+(Hanife%20Kolay%20International%20Beauty%20Academy)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Calculate population in area</a></iframe>
    </div>
    <div id='ContactText'>
        <h3>Adres</h3>
        <span>Yenişehir Mah. Osmanlı Bul. Aeropark Sitesi A Blok No: 11A <br/> İç Kapı No: 28 Posta Kodu: 34912 Pendik / İstanbul</span>
    </div>
    <div id='ContactText'>
      <h3>Telefon</h3>
      <span>+90 530 930 17 22</span>
    </div>
  </div>
  )
  
}

export default App;
